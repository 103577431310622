import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import constants from '../constants/API';
import { NEW_SECTION_8_22738, VIEW_ON_WATER_20461 } from '../featureFlags';
import booleanSwitch from '../tools/_boolean-switch';
import calcTheLargest from '../tools/_calc-the-largest';
import calcTheLeast from '../tools/_calc-the-least';
import changeStudioValue from '../tools/_change-studio-value';
import checkParameterAndToggleStatus from '../tools/_check-parameter-and-toggle-status';
import editPriceInMillions from '../tools/_edit-price-in-millions';
import getSelectedParams from '../tools/_get-selected-params';
import resetSortingParameter from '../tools/_reset-sorting-parameter';
import sortFlatsByPrice from '../tools/_sort-flats-by-price';
import sortNumber from '../tools/_sort-number';

//Используется в extraReducers
//получаем каталог квартир

const IS_NEW_SECTION_8 = flat => (NEW_SECTION_8_22738 ? flat.bulk.number : flat.section.number);

export const fetchCatalog = createAsyncThunk('flatsSlice/fetchFlats', async () => {
  const response = await axios.get(constants.url);
  return response.data;
});

export const fetchCatalogForC3 = createAsyncThunk('flatsSlice/fetchFlats', async () => {
  const response = await axios.get(constants.urlForC3);
  return response.data;
});

// получаем квартиру
export const fetchFlat = createAsyncThunk('flatsSlice/fetchFlat', async _ => {
  const url = constants.url.replace('/?blockId', `/${_.id}?blockId`);
  const response = await axios.get(url);
  return response.data;
});

export const fetchFlatForC3 = createAsyncThunk('flatsSlice/fetchFlat', async _ => {
  const url = constants.urlForC3.replace('/?blockId', `/${_.id}?blockId`);
  const response = await axios.get(url);
  return response.data;
});

const catalogSlice = createSlice({
  name: 'flatsSlice',
  initialState: {
    urls: {
      getResidential: {
        url: constants.url,
        initial: false,
        status: null,
        error: null,
      },
    },
    sectionPhase: {
      1: 'phase_1',
      2: 'phase_1',
      3: 'phase_1',
      4: 'phase_1',
      8: 'phase_2',
    },
    //Параметры фильтрации по чекбоксу
    filterParameters: {
      //Значения ползунков в фильтре по умолчанию
      initialValues: {
        area: [0, 10],
        price: [0, 10],
        floor: [0, 10],
      },
      byCheckbox: {
        // redPrice: [
        //     {name: "Квартира по акции", value: "redPrice", active: false, disabled: false},
        // ],
        rooms: [
          { name: 'СТ', value: '0', active: false, disabled: false },
          { name: '1', value: '1', active: false, disabled: false },
          { name: '2', value: '2', active: false, disabled: false },
          { name: '3', value: '3', active: false, disabled: false },
          { name: '4', value: '4', active: false, disabled: false },
        ],
        sections: [
          { name: '1', value: '1', active: false, disabled: false },
          { name: '2', value: '2', active: false, disabled: false },
          { name: '3', value: '3', active: false, disabled: true },
          { name: '4', value: '4', active: false, disabled: true },
          { name: '5', value: '5', active: false, disabled: true },
          { name: '6', value: '6', active: false, disabled: true },
          { name: '7', value: '7', active: false, disabled: true },
          { name: '8', value: '8', active: false, disabled: true },
        ],
        attributes: [
          { name: 'Отделка WhiteBox', value: 'whiteBox', active: false, disabled: false, info: '' },
          VIEW_ON_WATER_20461
            ? { name: 'Вид на воду', value: 'vidNaVoduForma', active: false, disabled: false, info: '' }
            : {},
          { name: 'Вид на реку', value: 'vidNaReku', active: false, disabled: false, info: '' },
          { name: 'Вид во внутренний двор', value: 'vidVoDvor', active: false, disabled: false, info: '' },
          { name: 'Угловое остекление', value: 'cornerGlazing', active: false, disabled: false, info: '' },
          { name: 'Панорамные окна', value: 'panoramicGlazing', active: false, disabled: false, info: '' },
          { name: 'Террасы', value: 'loggia', active: false, disabled: false, info: '' },
          { name: 'Пентхаус', value: 'penthaus', active: false, disabled: false, info: '' },
          { name: 'Окно в ванной комнате', value: 'oknoVVannoj', active: false, disabled: false, info: '' },
          { name: 'Гардеробная', value: 'dressingRoom', active: false, disabled: false, info: '' },
          {
            name: 'Мастер-спальня',
            value: 'masterBedroom',
            active: false,
            disabled: false,
            info: 'Пространство, объединяющее несколько функций: спальню, отдельную ванную комнату<br/>и&nbsp;иногда — гардеробную',
          },
        ].concat([
          { name: 'Вид на парк', value: 'vidNaPark', active: false, disabled: false, info: '' },
          //{ name: 'Угловое остекление', value: 'uglovoeOsteklenie', active: false, disabled: false },
          { name: 'Балкон', value: 'balcony', active: false, disabled: false, info: '' },
          { name: '2 санузла', value: 'bath', active: false, disabled: false, info: '' },
        ]),
      },
      selectedParams: {},
      //Список иконок, возможных для выбора в файле FlatsItemSvg по флагу "flats_"
      advantagesSvgIcons: [
        'highCeiling',
        'loggia',
        'patio',
        'livingKitchen',
        'sauna',
        'bath',
        'dressingRoom',
        'panoramicGlazing',
        'vidNaReku',
        'vidVoDvor',
        'oknaNa2Storony',
        'cornerGlazing',
      ],

      //Параметры фильтрации по range-слайдерам

      //Возможные параметры фильтрации
      params: ['floor', 'price', 'area'],
      //Параметры, которые были выбраны пользователем
      interactedParams: {
        floor: {
          min: false,
          max: false,
        },
        price: {
          min: false,
          max: false,
        },
        area: {
          min: false,
          max: false,
        },
      },
      //Выбранные параметры для отображения на странице в кнопках сброса
      ableToResetParams: {
        sections: [],
        rooms: [],
        floor: [],
        area: [],
        price: [],
        attributes: [],
      },
      inactiveSliders: {
        floor: false,
        price: false,
        area: false,
      },
      //Текущие значения слайдеров
      slidersValues: {
        price: [0, 10],
        area: [0, 10],
        floor: [0, 10],
      },
      //Крайние значения слайдеров
      slidersExtremeValues: {
        price: [0, 10],
        area: [0, 10],
        floor: [0, 10],
      },
      //Значения инпутов
      inputsValues: {
        price: [0, 10],
        area: [0, 10],
        floor: [0, 10],
      },
    },
    //Колонки таблицы для сортировки
    sortColumns: {
      number: 'Номер',
      rooms: 'Спальни',
      area: 'Площадь',
      section_number: 'Корпус',
      floor: 'Этаж',
      advantages: '',
      price: 'Стоимость',
      fav: '',
      scheme: '',
    },
    //Текущий параметр сортировки
    sortParameters: {
      value: 'price',
      direction: 'asc_', // сортировка по убыванию цены
      promo: false, // true - учитывать акции в сортировке цены
      priceStep: 1, // шаг в алгоритме сортировки колонки price
    },
    //Места вызова функции
    //по клику на заголовок столбца таблицы
    inTableHead: 'inTableHead',
    //после изменения состояния формы
    inForm: 'inForm',
    //Квартиры, которые выводятся на страницу
    shownFlats: [],
    //Все квартиры, взятые с сервера
    allFlats: [],
    //Похожие квартиры
    similarFlats: [],
    //Выбранная квартира
    currentFlat: '',
    number: '',
    //Статус квартиры
    flatStatus: {
      free: 'free',
      reserve: 'reserve',
    },
    //Список специальных условий
    benefits: [],
    //Активное спец.условие
    activeBenefit: {},
    projectDeadline: 'декабрь 2025 года',

    //Выбор по генплану
    //Выбор секции
    sectionsPage: {
      activeSectionId: null,
      filteredBySection: [],
      studiosArray: [],
      room1Array: [],
      room2Array: [],
      room3Array: [],
      room4Array: [],
      penthouseArray: [],
    },

    //Выбор этажа
    floorsPage: {
      activeFloorId: null,
      filteredByFloor: [],
      filteredByRooms: [],
      penthouseArray: [],
      arrays: {
        studiosArray: [],
        room1Array: [],
        room2Array: [],
        room3Array: [],
        room4Array: [],
      },
      minPrice: {
        studiosArray: null,
        room1Array: null,
        room2Array: null,
        room3Array: null,
        room4Array: null,
      },
    },
    //Фильтр по чекбоксу
    //Фильтрация по количеству комнат
    filterRooms: [
      { name: 'СТ', value: '0', active: false, disabled: false, array: 'studiosArray' },
      { name: '1', value: '1', active: false, disabled: false, array: 'room1Array' },
      { name: '2', value: '2', active: false, disabled: false, array: 'room2Array' },
      { name: '3', value: '3', active: false, disabled: false, array: 'room3Array' },
      { name: '4', value: '4', active: false, disabled: false, array: 'room4Array' },
    ],

    //Выбор квартиры на этаже
    floorPage: {
      filteredByFloor: [],
    },
    activeFloors: [],

    catalogPageDisplay: 'list',
    memberPath: '',
    currentParkingPlace: '',
  },
  reducers: {
    //Получение минимальных и максимальных параметров для фильтрации по range-слайдерам
    setExtremeSlidersValues(state) {
      if (state.allFlats.length === 0) {
        state.filterParameters.params.forEach(param => {
          const maxParam = state.filterParameters.initialValues[param][1],
            minParam = state.filterParameters.initialValues[param][0];

          state.filterParameters.slidersExtremeValues[param] = [minParam, maxParam];
          state.filterParameters.slidersValues[param] = [minParam, maxParam];
          state.filterParameters.inputsValues[param] = [minParam, maxParam];
        });
      }

      // Расчет максимумов и минимумов для слайдеров с обновленными данными
      state.filterParameters.params.forEach(param => {
        const interactedParams = state.filterParameters.interactedParams[param];

        const maxParam = calcTheLargest(state.shownFlats, param),
          minParam = calcTheLeast(state.shownFlats, param, maxParam);

        if (minParam === maxParam && minParam !== 0) {
          const [initialMinParam, initialMaxParam] = state.filterParameters.initialValues[param];

          if (!interactedParams.min) {
            state.filterParameters.slidersValues[param][0] = initialMinParam;
            state.filterParameters.inputsValues[param][0] = minParam;
            state.filterParameters.slidersExtremeValues[param][0] = initialMinParam;
          }

          if (!interactedParams.max) {
            state.filterParameters.slidersValues[param][1] = initialMaxParam;
            state.filterParameters.inputsValues[param][1] = maxParam;
            state.filterParameters.slidersExtremeValues[param][1] = initialMaxParam;
          }

          if (!interactedParams.min && !interactedParams.max) {
            state.filterParameters.inactiveSliders[param] = true;
          }
        } else if (minParam !== 0 && minParam !== maxParam) {
          state.filterParameters.inactiveSliders[param] = false;

          if (!interactedParams.min) {
            state.filterParameters.slidersValues[param][0] = minParam;
            state.filterParameters.inputsValues[param][0] = minParam;
            state.filterParameters.slidersExtremeValues[param][0] = minParam;
          }

          if (!interactedParams.max) {
            state.filterParameters.slidersValues[param][1] = maxParam;
            state.filterParameters.inputsValues[param][1] = maxParam;
            state.filterParameters.slidersExtremeValues[param][1] = maxParam;
          }
        }
      });
    },

    checkRelevantAttributes(state) {
      //Делаем все характеристики пустыми
      const attributes = state.filterParameters.byCheckbox.attributes.map(item => ({ value: item.value, empty: true }));

      //Если характеристика заполнена хотя бы у одной квартиры, то делаем её не пустой
      for (let i = 0; i < state.allFlats.length; i++) {
        const flat = state.allFlats[i];

        Object.keys(flat.attributes).forEach(key => {
          if (flat.attributes[key]) {
            attributes.forEach(item => {
              if (item.value === key) {
                item.empty = false;
              }
            });
          }
        });
      }

      state.filterParameters.byCheckbox.attributes = state.filterParameters.byCheckbox.attributes.filter(
        (item, index) => !attributes[index].empty,
      );
    },

    //Обновить значения для отображения в изменяемом range-слайдере
    updateInputValues(state, action) {
      //Записываем новые значения в активный слайдер
      state.filterParameters.inputsValues[action.payload.slider] = [
        Number(action.payload.values[0]),
        Number(action.payload.values[1]),
      ];
    },

    //Обновить значения для расчетов в изменяемом range-слайдере
    updateSliderValues(state, action) {
      //Проверка на изменение значений - если значения отличаются от максимальных/минимальных, то
      //пользователь взаимодействовал с ползунками, записываем это в interactedParams и
      //записываем новые значения в slidersValues
      //иначе - пользователь вернул значения в исходное положение, записываем false в interactedParams
      //и записываем значения из initialValues в slidersValues
      const slider = action.payload.slider;
      const newSliderValues = [Number(action.payload.values[0]), Number(action.payload.values[1])];
      const extremeValues = state.filterParameters.slidersExtremeValues[action.payload.slider];
      const initialValues = state.filterParameters.initialValues[action.payload.slider];
      const newValuesAreEqual = newSliderValues[0] === newSliderValues[1];

      newSliderValues.forEach((value, index) => {
        const key = index === 0 ? 'min' : 'max';
        const preposition = index === 0 ? 'от ' : 'до ';

        if (value !== extremeValues[index]) {
          state.filterParameters.interactedParams[slider][key] = true;
          state.filterParameters.slidersValues[slider][index] = value;
          state.filterParameters.ableToResetParams[slider][index] = newValuesAreEqual
            ? (value > 1000000 ? editPriceInMillions(value).toFixed(1) : value) +
              (slider === 'price' ? ' млн. руб.' : slider === 'area' ? ' м²' : '')
            : preposition +
              ' ' +
              (action.payload.values[0] > 1000000 ? editPriceInMillions(value).toFixed(1) : value) +
              (slider === 'price' ? ' млн. руб.' : slider === 'area' ? ' м²' : '');
        } else {
          state.filterParameters.interactedParams[slider][key] = false;
          state.filterParameters.slidersValues[slider][index] = initialValues[index];
          state.filterParameters.ableToResetParams[slider][index] = '';
        }
      });

      catalogSlice.caseReducers.filterFlats(state);
    },

    resetParam(state, action) {
      const sliders = ['floor', 'area', 'price'];
      const checkboxes = ['rooms', 'sections', 'attributes'];
      const param = action.payload;

      if (sliders.includes(param)) {
        state.filterParameters.interactedParams[param].min = false;
        state.filterParameters.interactedParams[param].max = false;
        catalogSlice.caseReducers.setExtremeSlidersValues(state);
      }

      if (checkboxes.includes(param)) {
        state.filterParameters.byCheckbox[param].forEach(item => (item.active = false));
      }

      catalogSlice.caseReducers.filterFlats(state);
      state.filterParameters.ableToResetParams[param] = [];
    },
    toggleActiveSearchParams(state, action) {
      const toggleParam = (array, value) => {
        const index = array.indexOf(value);

        if (index === -1) {
          array.push(value);
        } else {
          array.splice(index, 1);
        }
      };

      if (action.payload.id.includes('rooms')) {
        booleanSwitch(state.filterParameters.byCheckbox, 'rooms', 'active', action.payload.value);
        toggleParam(state.filterParameters.ableToResetParams.rooms, action.payload.value);
        state.filterParameters.ableToResetParams.rooms.sort((a, b) => a - b);
      } else if (action.payload.id.includes('sections')) {
        booleanSwitch(state.filterParameters.byCheckbox, 'sections', 'active', action.payload.value);
        toggleParam(state.filterParameters.ableToResetParams.sections, action.payload.value);
        state.filterParameters.ableToResetParams.sections.sort((a, b) => a - b);
      } else if (action.payload.id.includes('attributes')) {
        booleanSwitch(state.filterParameters.byCheckbox, 'attributes', 'active', action.payload.value);
        toggleParam(state.filterParameters.ableToResetParams.attributes, action.payload.value);
      }

      catalogSlice.caseReducers.filterFlats(state);
    },

    filterFlats(state) {
      //Фильтрация по range-слайдерам
      const interactedParams = state.filterParameters.interactedParams;

      const slidersValues = {
        price: [0, 0],
        area: [0, 0],
        floor: [0, 0],
      };

      Object.keys(interactedParams).forEach(key => {
        if (interactedParams[key].min) {
          slidersValues[key][0] = state.filterParameters.slidersValues[key][0];
        } else {
          slidersValues[key][0] = state.filterParameters.initialValues[key][0];
        }

        if (interactedParams[key].max) {
          slidersValues[key][1] = state.filterParameters.slidersValues[key][1];
        } else {
          slidersValues[key][1] = state.filterParameters.initialValues[key][1];
        }
      });

      const bySliders = flat => {
        return (
          flat.currentPrice >= slidersValues.price[0] &&
          flat.currentPrice <= slidersValues.price[1] &&
          flat.area >= slidersValues.area[0] &&
          flat.area <= slidersValues.area[1] &&
          flat.floor >= slidersValues.floor[0] &&
          flat.floor <= slidersValues.floor[1]
        );
      };

      const filtered = state.allFlats.filter(bySliders);

      //Фильтрация по чекбоксам

      //Получение актуальных параметров для фильтрации
      state.filterParameters.selectedParams = {
        rooms: getSelectedParams(state.filterParameters.byCheckbox, 'rooms').replace('0', 'studio'),
        sections: getSelectedParams(state.filterParameters.byCheckbox, 'sections'),
        attributes: getSelectedParams(state.filterParameters.byCheckbox, 'attributes').split(','),
      };

      //Делаем все не выбранные характеристики недоступными
      Object.keys(state.filterParameters.byCheckbox).forEach(key => {
        state.filterParameters.byCheckbox[key].forEach(item => {
          item.disabled = !item.active;
        });
      });

      const bySections = flat => {
        return state.filterParameters.selectedParams.sections
          ? state.filterParameters.selectedParams.sections.includes(IS_NEW_SECTION_8(flat))
          : true;
      };

      const byAttributes = flat => {
        for (let i = 0; i < state.filterParameters.selectedParams.attributes.length; i++) {
          if (!flat.attributes[state.filterParameters.selectedParams.attributes[i]]) {
            return false;
          }
        }
        return true;
      };

      const byRooms = flat => {
        return state.filterParameters.selectedParams.rooms
          ? state.filterParameters.selectedParams.rooms.includes(flat.rooms)
          : true;
      };

      const byCheckbox = {
        rooms: byRooms,
        sections: bySections,
        attributes: byAttributes,
      };

      const findRoom = flat => {
        state.filterParameters.byCheckbox.rooms.forEach(item => {
          if ((item.value === '0' && flat.rooms === 'studio') || flat.rooms === Number(item.value)) {
            item.disabled = false;
          }
        });
      };

      const findSection = flat => {
        state.filterParameters.byCheckbox.sections.forEach(item => {
          if (IS_NEW_SECTION_8(flat) === Number(item.value)) {
            item.disabled = false;
          }
        });
      };

      const findAttribute = flat => {
        state.filterParameters.byCheckbox.attributes.forEach(item => {
          if (flat.attributes[item.value]) {
            item.disabled = false;
          }
        });
      };

      const findCheckbox = {
        rooms: findRoom,
        sections: findSection,
        attributes: findAttribute,
      };

      const params = ['rooms', 'sections', 'attributes'];
      const chosenParams = [];
      Object.keys(state.filterParameters.selectedParams).forEach(key => {
        const param = state.filterParameters.selectedParams[key];
        if ((param && param.length === 0) || (param.length > 0 && param[0])) {
          chosenParams.push(key);
        }
      });

      const amountOfChosenParams = chosenParams.length;

      if (amountOfChosenParams === 0) {
        state.shownFlats = filtered;
        catalogSlice.caseReducers.setAvailableCheckboxes(state);
      }

      if (amountOfChosenParams === 1) {
        if (chosenParams[0] !== 'attributes') {
          filtered.forEach(flat => {
            findCheckbox[chosenParams[0]](flat);
          });
        }

        state.shownFlats = filtered.filter(byCheckbox[chosenParams[0]]);
        state.shownFlats.forEach(flat => {
          findCheckbox.attributes(flat);
        });

        const restOfParams = params.filter(param => param !== chosenParams[0] && param !== 'attributes');

        state.shownFlats.forEach(flat => {
          restOfParams.forEach(param => {
            findCheckbox[param](flat);
          });
        });
      }

      if (amountOfChosenParams === 2) {
        const thirdParam = params.filter(param => param !== chosenParams[0] && param !== chosenParams[1])[0];

        state.shownFlats = filtered.filter(byCheckbox[chosenParams[0]]).filter(byCheckbox[chosenParams[1]]);
        state.shownFlats.forEach(flat => {
          findCheckbox.attributes(flat);
          findCheckbox[thirdParam](flat);
        });

        const filteredByFirstParam = filtered.filter(byCheckbox[chosenParams[0]]);
        filteredByFirstParam.forEach(flat => {
          findCheckbox[chosenParams[1]](flat);
        });

        const filteredBySecondParam = filtered.filter(byCheckbox[chosenParams[1]]);
        filteredBySecondParam.forEach(flat => {
          findCheckbox[chosenParams[0]](flat);
        });
      }

      if (amountOfChosenParams === 3) {
        state.shownFlats = filtered.filter(byCheckbox.sections).filter(byCheckbox.rooms).filter(byCheckbox.attributes);

        state.shownFlats.forEach(flat => {
          findCheckbox.attributes(flat);
        });

        const filteredBySectionsAndAttributes = filtered.filter(byCheckbox.sections).filter(byCheckbox.attributes);
        filteredBySectionsAndAttributes.forEach(flat => {
          findCheckbox.rooms(flat);
        });

        const filteredByRoomsAndAttributes = filtered.filter(byCheckbox.rooms).filter(byCheckbox.attributes);
        filteredByRoomsAndAttributes.forEach(flat => {
          findCheckbox.sections(flat);
        });
      }

      catalogSlice.caseReducers.setExtremeSlidersValues(state);
    },

    setAvailableCheckboxes(state) {
      //Делаем все не выбранные характеристики недоступными
      Object.keys(state.filterParameters.byCheckbox).forEach(key => {
        state.filterParameters.byCheckbox[key].forEach(item => {
          item.disabled = !item.active;
        });
      });
      //Если характеристика заполнена хотя бы у одной квартиры, то делаем её доступной
      for (let i = 0; i < state.shownFlats.length; i++) {
        const flat = state.shownFlats[i];

        Object.keys(flat.attributes).forEach(key => {
          if (flat.attributes[key]) {
            state.filterParameters.byCheckbox.attributes.forEach(item => {
              if (item.value === key) {
                item.disabled = false;
              }
            });
          }
        });

        state.filterParameters.byCheckbox.sections.forEach(item => {
          if (IS_NEW_SECTION_8(flat) === Number(item.value)) {
            item.disabled = false;
          }
        });

        state.filterParameters.byCheckbox.rooms.forEach(item => {
          if ((item.value === '0' && flat.rooms === 'studio') || flat.rooms === Number(item.value)) {
            item.disabled = false;
          }
        });
      }
    },
    resetFilters(state) {
      const checkboxParams = Object.keys(state.filterParameters.byCheckbox);

      checkboxParams.forEach(item => {
        state.filterParameters.byCheckbox[item] = resetSortingParameter(state.filterParameters.byCheckbox[item]);
      });

      const interactedParams = Object.keys(state.filterParameters.interactedParams);
      interactedParams.forEach(item => {
        state.filterParameters.interactedParams[item].max = false;
        state.filterParameters.interactedParams[item].min = false;
      });

      Object.keys(state.filterParameters.ableToResetParams).forEach(
        key => (state.filterParameters.ableToResetParams[key] = []),
      );

      state.shownFlats = state.allFlats;
      catalogSlice.caseReducers.setExtremeSlidersValues(state);
      catalogSlice.caseReducers.setAvailableCheckboxes(state);

      state.sortParameters = {
        value: 'area',
        direction: 'asc_',
        promo: false,
        priceStep: 1,
      };

      catalogSlice.caseReducers.sortFlats(state, {
        payload: {
          value: state.sortParameters.value,
          placeCall: state.inForm,
          array: 'shownFlats',
        },
      });
    },
    changeCatalogPageDisplay(state, action) {
      state.catalogPageDisplay = action.payload;
    },
    setMemberPath(state, action) {
      state.memberPath = action.payload;
    },
    highlightRoom(state, action) {
      booleanSwitch(state, 'filterRooms', 'active', action.payload);
    },
    resetRoom(state) {
      state.filterRooms.map(item => (item.active = false));
      state.floorsPage.filteredByRooms = [];
    },
    sortByRoom(state) {
      const rooms = getSelectedParams(state, 'filterRooms').replace('0', 'studio');
      const byRooms = flat => {
        return rooms ? rooms.includes(flat.rooms) : flat;
      };

      state.floorsPage.filteredByRooms = state.sectionsPage.filteredBySection.filter(byRooms);
    },
    setActiveFloors(state, action) {
      state.activeFloors = [];
      state.sectionsPage.activeSectionId = action.payload.houseId;
      state.sectionsPage.filteredBySection = state.allFlats.filter(
        item => item.bulk.number === Number(state.sectionsPage.activeSectionId),
      );
      //Считаем самый первый и самый последний этаж
      const maxFloor = calcTheLargest(state.sectionsPage.filteredBySection, 'floor');
      const minFloor = calcTheLeast(state.sectionsPage.filteredBySection, 'floor', maxFloor);

      for (let i = minFloor; i <= maxFloor; ++i) {
        const filtered = state.sectionsPage.filteredBySection.filter(item => item.floor === i);

        if (filtered.length > 0) {
          state.activeFloors.push({
            id: i,
            disabled: false,
          });
        } else {
          state.activeFloors.push({
            id: i,
            disabled: true,
          });
        }
      }
    },

    sortFlats(state, action) {
      // Исключаем фильтр по характеристикам
      if (action.payload.value !== 'advantages') {
        let { value, direction, promo, priceStep } = state.sortParameters;

        if (action.payload.placeCall === state.inTableHead) {
          if (value === action.payload.value) {
            //Если вызов произошел по клику на заголовок таблицы в той же колонке
            //переключить направления сортировки
            if (direction === 'asc_') {
              direction = 'dec_';
            } else {
              direction = 'asc_';
            }

            if (value === 'price' && promo && direction === 'asc_') {
              // переход на шаг 3
              promo = false;
            }
          } else {
            value = action.payload.value;
            if (value === 'price') {
              // переход на шаг 3
              promo = false;
              direction = 'asc_';
            }
          }

          if (value === 'price' && priceStep < 4) {
            priceStep++;
          }

          state.sortParameters = {
            value,
            direction,
            promo,
            priceStep,
          };
        }

        if (value === 'rooms') {
          const array = state[action.payload.array].map(flat => changeStudioValue(flat, 'studio', '0'));
          sortNumber(array, value, direction);
          state[action.payload.array] = array.map(flat => changeStudioValue(flat, '0', 'studio'));
        } else if (value === 'section_number') {
          if (direction === 'asc_') {
            state[action.payload.array].sort((a, b) => a.section.number - b.section.number);
          } else {
            state[action.payload.array].sort((a, b) => b.section.number - a.section.number);
          }
        } else if (value === 'price') {
          sortFlatsByPrice(state[action.payload.array], promo, direction);
        } else {
          sortNumber(state[action.payload.array], value, direction);
        }
      }
    },

    resetCurrentFlat(state) {
      state.currentFlat = '';
      state.number = '';
    },
    setBenefits(state, action) {
      //При переходе на страницу записываем в стейт все доступные формы привелегий,
      //"Выгодная цена" по умолчанию активна
      state.benefits = action.payload.map(item => checkParameterAndToggleStatus(item.id, 125191, item, 116457));
      state.activeBenefit = state.benefits.find(item => item.checked === true);
    },
    toggleActiveBenefit(state, action) {
      if (action.payload !== state.activeBenefit.id) {
        state.benefits = state.benefits.map(item => checkParameterAndToggleStatus(item.id, action.payload, item));
        state.activeBenefit = state.benefits.find(item => item.checked === true);
      }
    },
    filterBySection(state, action) {
      if (state.sectionsPage.activeSectionId !== action.payload && state.allFlats.length > 0) {
        state.sectionsPage.activeSectionId = action.payload;
        state.sectionsPage.filteredBySection = state.allFlats.filter(
          item => item.bulk.number === Number(state.sectionsPage.activeSectionId),
        );

        const rooms = [
          {
            number: 'studio',
            array: 'studiosArray',
            value: '0',
          },
          {
            number: 1,
            array: 'room1Array',
            value: '1',
          },
          {
            number: 2,
            array: 'room2Array',
            value: '2',
          },
          {
            number: 3,
            array: 'room3Array',
            value: '3',
          },
          {
            number: 4,
            array: 'room4Array',
            value: '4',
          },
        ];

        rooms.forEach(room => {
          //Расчет количества квартир с разным количеством комнат в одной секции (доме)
          state.sectionsPage[room.array] = state.sectionsPage.filteredBySection.filter(
            item => item.rooms === room.number,
          );
          state.filterRooms.find(e => e.value === room.value).active = false;
          //Если в секции (доме) нет квартир с определенным количеством комнат, то в форме фильтрации делаем кнопку не активной
          if (state.sectionsPage[room.array].length === 0) {
            state.filterRooms.find(e => e.value === room.value).disabled = true;
          } else {
            state.filterRooms.find(e => e.value === room.value).disabled = false;
          }
        });
        state.sectionsPage.penthouseArray = state.sectionsPage.filteredBySection.filter(
          item => item.attributes.penthaus,
        );
      }
    },
    filterByRoom(state, action) {
      if (state.floorsPage.activeFloorId !== action.payload.id) {
        state.floorsPage.activeFloorId = action.payload.id;

        state.sectionsPage.filteredBySection = state.allFlats.filter(
          item => item.bulk.number === Number(action.payload.houseId),
        );
        state.floorsPage.filteredByFloor = state.sectionsPage.filteredBySection.filter(
          item => item.floor === Number(action.payload.id),
        );

        //Если квартиры по этажу не отфильтрованы
        if (state.floorsPage.filteredByFloor.length > 0) {
          //Вариации квартир
          const rooms = [
            {
              id: 'studio',
              array: 'studiosArray',
            },
            {
              id: 1,
              array: 'room1Array',
            },
            {
              id: 2,
              array: 'room2Array',
            },
            {
              id: 3,
              array: 'room3Array',
            },
            {
              id: 4,
              array: 'room4Array',
            },
          ];

          rooms.forEach(item => {
            //Сначала ищем в отфильтрованных по этажу квартирах все квартиры с определенным количеством комнат
            state.floorsPage.arrays[`${item.array}`] = state.floorsPage.filteredByFloor.filter(
              elem => elem.rooms === (item.id === 'studio' ? item.id : Number(item.id)),
            );

            //Если квартиры с данным количеством комнат есть
            if (state.floorsPage.arrays[`${item.array}`].length > 0) {
              //Считаем максимальную и минимальную стоимость квартир по ключу currentPrice
              const maxPrice = calcTheLargest(state.floorsPage.arrays[`${item.array}`], 'price');
              state.floorsPage.minPrice[`${item.array}`] = calcTheLeast(
                state.floorsPage.arrays[`${item.array}`],
                'price',
                maxPrice,
              );
            }
          });
        }
      }
    },
    filterByFloor(state, action) {
      state.floorsPage.activeFloorId = action.payload.floorId;
      state.sectionsPage.activeSectionId = action.payload.houseId;

      state.sectionsPage.filteredBySection = state.allFlats.filter(
        item => item.bulk.number === Number(state.sectionsPage.activeSectionId),
      );

      state.floorPage.filteredByFloor = state.sectionsPage.filteredBySection.filter(
        item => item.floor === Number(state.floorsPage.activeFloorId),
      );
    },
    setCurrentParkingPlace(state, action) {
      state.currentParkingPlace = action.payload;
    },
    setSortPromo(state, action) {
      state.sortParameters.promo = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCatalog.pending, state => {
        state.urls.getResidential.status = 'loading';
        state.urls.getResidential.error = null;
      })
      .addCase(fetchCatalog.fulfilled, (state, action) => {
        const freeFlats = action.payload.flats.filter(item => item.status === 'free');
        state.shownFlats = freeFlats;
        state.allFlats = freeFlats;

        const maxFloor = calcTheLargest(state.shownFlats, 'floor');
        const minFloor = calcTheLeast(state.shownFlats, 'floor', maxFloor);

        state.filterParameters.initialValues = {
          area: [action.payload.area.min, action.payload.area.max],
          price: [action.payload.price.min, action.payload.price.max],
          floor: [minFloor, maxFloor],
        };

        catalogSlice.caseReducers.checkRelevantAttributes(state);
        catalogSlice.caseReducers.setExtremeSlidersValues(state);
        catalogSlice.caseReducers.setAvailableCheckboxes(state);

        state.urls.getResidential.initial = true;
        state.urls.getResidential.status = true;

        catalogSlice.caseReducers.sortFlats(state, {
          payload: {
            value: state.sortParameters.value,
            placeCall: state.inForm,
            array: 'shownFlats',
          },
        });
      })
      .addCase(fetchCatalog.rejected, state => {
        // eslint-disable-next-line no-console
        console.log('Ошибка при запросе на сервер');
        state.urls.getResidential.error = 'error';
        state.urls.getResidential.status = 'error';
      })
      .addCase(fetchFlat.fulfilled, (state, action) => {
        //в action.payload приходит flat
        state.number = action.payload.number;
        state.currentFlat = action.payload;
        state.similarFlats = action.payload.similar.map(f =>
          f.real_price > f.currentPrice ? { ...f, redPrice: true, mainBenefit: {} } : f,
        );
      })
      .addCase(fetchFlat.pending, state => {
        state.similarFlats = [];
      })
      .addCase(fetchFlat.rejected, state => {
        state.similarFlats = [];
        state.urls.getResidential.status = 'error';
      });
  },
});

export default catalogSlice.reducer;
export const {
  filterFlats,
  setExtremeSlidersValues,
  toggleActiveSearchParams,
  sortFlats,
  setCurrentFlat,
  resetCurrentFlat,
  highlightRoom,
  sortByRoom,
  setActiveFloors,
  resetRoom,
  changeCatalogPageDisplay,
  setMemberPath,
  resetFilters,
  updateInputValues,
  updateSliderValues,
  filterByFloor,
  filterByRoom,
  filterBySection,
  resetParam,
  setCurrentParkingPlace,
  setSortPromo,
} = catalogSlice.actions;
